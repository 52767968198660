<template>
  <svg viewBox="0 0 22 25" :width="size">
    <path
      class="cls-1"
      d="M8.7 10.6c.1.6 0 1.2-.4 1.7-.3.4-.8.7-1.4.7H1.8c-.5 0-1.1-.2-1.4-.7-.4-.4-.5-1.1-.4-1.7.4-2 1.3-3.5 2.5-4.3C1.6 5.7.9 4.6.9 3.4 1 1.5 2.5 0 4.4 0s3.4 1.5 3.4 3.4-.6 2.3-1.6 2.9c1.2.8 2.1 2.4 2.5 4.3zM10.8 22.8H3.4c-.5 0-.9-.4-.9-.9v-7c0-.5-.4-1-1-1s-1 .4-1 1v7c0 1.5 1.3 2.7 2.8 2.7h7.3c.5 0 .9-.4.9-.9s-.4-1-1-1zM10.8 1.9h7.4c.5 0 .9.4.9.9v7c0 .5.4 1 1 1s1-.4 1-1V2.7C20.9 1.2 19.7 0 18.2 0h-7.3c-.6 0-1.1.4-1.1.9s.4 1 1 1zM12.9 22.4c-.1.6 0 1.2.4 1.7.3.4.8.7 1.4.7h5.1c.5 0 1.1-.2 1.4-.7.4-.4.5-1.1.4-1.7-.4-2-1.3-3.5-2.5-4.3.9-.6 1.6-1.7 1.6-2.9 0-1.9-1.5-3.4-3.4-3.4s-3.4 1.5-3.4 3.4.6 2.3 1.6 2.9c-1.2.8-2.1 2.4-2.5 4.3z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: 18
    }
  }
};
</script>
